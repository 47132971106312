import React, { Component } from "react";
// import { AnimationContainer } from "./AnimationContainer";
import { hsv2rgb, arrayToColorString } from "../helpers/color/color";
import "./Background.css";
// import { animationMeta } from "../animations/bananaSmall/meta.json";
export class Background extends Component {
  static displayName = Background.name;

  constructor(props) {
    super(props);
    this.state = {
      images: [],
      loading: true,
      currentFrame: 0,
      fps: 0,
      squares: [],
      interval: null,
      frameViewer: null,
      playing: true,
    };
    this.ref = React.createRef();
    this.calculateNumberOfSqures = this.calculateNumberOfSqures.bind(this);
    this.play = this.play.bind(this);
    this.playPause = this.playPause.bind(this);
    this.nextFrame = this.nextFrame.bind(this);
    this.getDrawenSquares = this.getDrawenSquares.bind(this);
    this.lock = false;
  }

  getDrawenSquares() {
    return this.state.squares;
  }

  componentDidMount() {
    this.loadImages();
    const element = this.ref.current;
    window.addEventListener("resize", (event) => {
      this.componentDidUpdate();
    });
  }

  calculateNumberOfSqures() {
    const element = this.ref.current;
    let singleValues = window
      .getComputedStyle(element)
      .getPropertyValue("grid-template-columns")
      .split(" ");
    let numberOfCols = singleValues.length;
    let singleValue = parseFloat(singleValues[0], 10);
    let numberOfRows =
      Math.floor(element.offsetHeight / singleValue) +
      (element.offsetHeight % singleValue ? 1 : 0);
    return numberOfCols * numberOfRows;
  }

  componentDidUpdate() {
    let squres = this.state.squares;
    let num = this.calculateNumberOfSqures();
    if (this.state.squares.length < num) {
      while (squres.length < num) squres.push(this.getSqure(squres.length));
      this.setState({ squares: squres });
    } else if (this.state.squares.length > num) {
      while (squres.length > num) squres.pop();
      this.setState({ squares: squres });
    }
    if (this.state.interval == null && this.state.playing) {
      this.play();
    }
  }

  nextFrame() {
    this.setState({
      currentFrame:
        this.state.currentFrame + 1 >= this.state.images.length
          ? 0
          : this.state.currentFrame + 1,
    });
    this.setState({
      frameViewer:
        this.state.currentFrame + 1 >= this.state.images.length
          ? 0
          : this.state.currentFrame + 1,
    });
  }

  getSqure(key) {
    let hue = Math.random() * 360;
    let bgg = arrayToColorString(hsv2rgb(hue, 0.4, 1));
    return () => (
      <div
        className="squre-bgg-container"
        style={{ background: bgg }}
        key={key}
        hue={hue}
      >
        <div className="squre-bgg-container-inside">
          <img src={this.state.images[this.state.currentFrame]} />
        </div>
      </div>
    );
  }

  render() {
    let sq = this.state.squares.map((sqFunc) => sqFunc());
    return (
      <div className="bgg-grid-container" ref={this.ref}>
        {sq}
      </div>
    );
  }

  async loadImages() {
    const response = await fetch(`${process.env.PUBLIC_URL}/animations/bananaSmall/meta.json`);
    const data = await response.json();
    var dataImages = [];
    for (let num = 0; num < data["length"]; num++) {
      var fileName = "0000000".substring(0, 7 - num.toString().length) + num + "." + data["extension"];
      var imgResponse = await fetch(`${process.env.PUBLIC_URL}/animations/bananaSmall/${fileName}`);
      dataImages.push(URL.createObjectURL(await imgResponse.blob()));
    }
    this.setState({ images: dataImages, loading: false, fps: data["fps"] });
    this.play(data["fps"]);
  }

  playPause(play) {
    if (play) {
      if (this.state.interval != null) {
        return;
      }
      let interval = 1000 / this.state.fps;
      let temp = setInterval(this.nextFrame, interval);
      this.setState({ interval: temp, playing: play });
    } else {
      clearInterval(this.state.interval);
      if (this.state.interval == null) this.lock = true;
      this.setState({ interval: null, playing: play });
    }
  }

  play(fps) {
    if (this.lock) {
      return;
    }
    this.lock = true;
    if (this.state.interval != null) {
      clearInterval(this.state.interval);
      this.setState({ interval: null });
    }

    let interval = 1000 / fps ?? this.state.fps;
    let temp = setInterval(this.nextFrame, interval);
    this.setState({ interval: temp });
    this.lock = false;
  }
}
